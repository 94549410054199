/* You can add global styles to this file, and also import other style files */
@import url(normalize.css/normalize.css);
@import url(@angular/cdk/overlay-prebuilt.css);
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

/* BULMA */
*,
*::before,
*::after {
  box-sizing: inherit;
}

blockquote,
body,
dd,
dl,
dt,
fieldset,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
html,
iframe,
legend,
li,
ol,
p,
pre,
textarea,
ul {
  margin: 0;
  padding: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

table {
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

html {
  box-sizing: border-box;
  background-color: white;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow-y: overlay;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

body {
  color: #4a4a4a !important;
  font-size: 15pt !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}

.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
}

.columns {
  display: flex;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.75rem;
}

/* BULMA ENDS */

@-ms-viewport {
  width: auto;
  height: 100vh;
}

@supports (-ms-ime-align: auto) {
  html {
    overflow-y: hidden;
  }
}

body,
button,
input {
  font-family: "Roboto", sans-serif !important;
  border: 0;
}

button,
input {
  font-size: 13pt;
}

button:active,
button:focus,
input:active,
input:focus,
select:active,
select:focus,
body {
  outline: none;
}

input::placeholder {
  color: #b6c3cc !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}

input.error:-webkit-autofill,
input.error:-webkit-autofill:hover,
input.error:-webkit-autofill:focus,
input.error:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #fde1e0 inset !important;
}

a {
  color: #007acc;
  text-decoration: none;
}

.button-Primary {
  background-color: #007acc !important;
}

.button-Primary:hover {
  background-color: #005c99 !important;
}

.button-Primary:active {
  background-color: #003d66 !important;
}

.button-Secondary {
  background-color: #b6c3cc !important;
}

.button-Secondary:hover {
  background-color: #a3b5bf !important;
}

.button-Secondary:active {
  background-color: #889aa5 !important;
}

.button-Disabled {
  border: 1px solid #b6c3cc !important;
  color: #b6c3cc !important;
  background-color: #fff !important;
}

.button-Disabled:hover {
  color: #b6c3cc !important;
  background-color: #fff !important;
}

.button-Disabled:active {
  color: #b6c3cc !important;
  background-color: #fff !important;
}
.mat-mdc-checkbox-layout .mat-mdc-checkbox-label {
  line-height: 24px;
}

.chips-item {
  display: inline-block;
  margin-right: 10px;
}

.mat-mdc-button,
.mat-mdc-unelevated-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button {
  line-height: 30px !important;
  font-size: 15px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.mat-mdc-unelevated-button.mat-secondary {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.mat-mdc-unelevated-button.mat-primary {
  border-radius: 0  !important;
  background-color: #d8e0e5 !important;
  color: #2c404c !important;
}


.mat-mdc-unelevated-button.mat-primary:hover {
  background-color: #a3b5bf !important;
}

.mat-mdc-unelevated-button.mat-primary:active {
  background-color: #889aa5 !important;
}

.mat-mdc-unelevated-button.mat-accent {
  border-radius: 0;
}

.mat-mdc-unelevated-button.mat-accent.disabled,
.mat-mdc-flat-button.mat-primary.disabled {
  outline: 1px solid rgba(0, 0, 0, 0.12) !important;
  background-color: white !important;
  color: rgba(0, 0, 0, 0.12) !important;
}

.mdc-checkbox__checkmark {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.6, 1);
  color:  #fff !important;
  background-color: #007acc;
}

.mat-mdc-unelevated-button.mat-accent:not(.disabled):hover {
  background-color: #005c99 !important;
}

mat-mdc-unelevated-button:not(:disabled) {
  color: #fff;
  background-color: #007acc;
}

.mdc-checkbox__background {
  border: 1px solid #0000008a !important;
  border-radius: 2px !important;
  }

.mat-mdc-unelevated-button.mat-accent:not(.disabled):active {
  background-color: #005c99 !important;
}

.mat-mdc-unelevated-button.mat-primary:not(.disabled):hover {
  background-color: #d8e0e5 !important;
  color: #465863 !important;
}

.mat-mdc-unelevated-button.mat-primary:not(.disabled):active {
  background-color: #d8e0e5 !important;
  color: #465863 !important;
}

.main {
  overflow-y: auto !important ;
  max-height: 100vh;
}


.main-feedbackButton .mat-mdc-button-wrapper {
  display: flex;
  padding: 4px 0;
}

.mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked .mat-mdc-radio-outer-circle {
  border-color: #007acc !important;
  background-color: #007acc !important;
}

.mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-inner-circle {
  background-color: white !important;
}

.mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-ripple {
  background-color: #007acc !important;
  height: 1px;
}

.mat-mdc-form-field .mat-mdc-form-field-label {
  text-transform: uppercase;
  font-family: "Roboto", sans-serif !important;
}

.mat-mdc-form-field.mat-mdc-form-field-underline {
  background-color: #d8e0e5 !important;
}


.mat-mdc-select {
  font-size: 15px;
  font-family: "Roboto", sans-serif !important;
}

.mat-mdc-select-arrow svg {
  fill: none !important;
}

.mat-mdc-select .mat-mdc-select-arrow {
  border-right: none;
  border-style: solid;
  border-width: 1px 1px 0 0;
  content: "";
  display: inline-block;
  height: 0.45em;
  left: 0.15em;
  position: relative;
  transform: rotate(135deg);
  vertical-align: top;
  width: 0.45em;
}

.mat-mdc-table {
  font-family: "Roboto", sans-serif;
}

.mat-mdc-table tr.mat-mdc-header-row {
  height: 26px;
}

.mat-mdc-table .mat-mdc-header-cell {
  font-size: 12px;
  vertical-align: middle !important;
  font-weight: 400;
  text-transform: uppercase;
  border-bottom: 1px solid #677e8c;
  color: #748b99;
  z-index: 0 !important;
}

.mat-mdc-table td.mat-mdc-cell:first-of-type,
.mat-mdc-table td.mat-mdc-footer-cell:first-of-type,
.mat-mdc-table th.mat-mdc-header-cell:first-of-type {
  padding-left: 10px;
  padding-right: 15px;
}

.mat-mdc-table td.mat-mdc-cell:last-of-type,
.mat-mdc-table td.mat-mdc-footer-cell:last-of-type,
.mat-mdc-table th.mat-mdc-header-cell:last-of-type {
  padding-right: 10px;
}

.mat-mdc-table tr.mat-mdc-footer-row,
.mat-mdc-table tr.mat-mdc-row {
  min-height: 40px;
  height: 40px;
}

.mat-mdc-table .mat-column-select {
  padding: 0 15px 0 10px;
  width: 36px;
  z-index: 90 !important;
}

.mat-mdc-table .mat-column-flag {
  padding-right: 16px;
  width: 62px;
  cursor: pointer;
  z-index: 0 !important;
}

.mat-mdc-table .mat-column-containerNumber {
  width: 130px;
  max-width: 130px;
  padding-right: 1.25vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mat-mdc-table .mat-column-destinationTerminal {
  width: 92px;
  max-width: 92px;
  padding-right: 1.25vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mat-mdc-table .mat-column-equipmentCode,
.mat-mdc-table .mat-column-equipmentCategory {
  width: 90px;
  padding-right: 1.25vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mat-mdc-table .mat-column-lastFreeDay {
  width: 110px;
  padding-right: 1.25vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mat-mdc-table .mat-column-unloadEvent {
  width: 165px;
  padding-right: 1.25vw;
  padding-left: 25px;
  overflow: hidden;
  overflow: hidden;
  white-space: nowrap;
}

.mat-mdc-table .mat-column-holdStatusAsEnum {
  width: 90px;
  max-width: 90px;
  min-width: 90px;
  padding-right: 1.25vw;
  white-space: nowrap;
  overflow: hidden;
  white-space: nowrap;
}

.mat-mdc-table .mat-column-holdStatusAsEnum img {
  cursor: pointer;
}

.mat-mdc-table .mat-column-bolNumbers,
.mat-mdc-table .mat-column-eventdate {
  max-width: 130px;
  width: 120px;
  padding-right: 1.25vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mat-mdc-table .mat-column-averageBerthTime,
.mat-mdc-table .mat-column-averageDischargeTime,
.mat-mdc-table .mat-column-averageLoadTime,
.mat-mdc-table .mat-column-emptyReturns {
  width: 170px;
  padding-right: 1.25vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mat-mdc-table .mat-column-etaAta {
  max-width: 110px;
  width: 110px;
  padding-right: 1.25vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mat-mdc-table .mat-column-vesselName,
.mat-mdc-table .mat-column-shippingLine,
.mat-mdc-table  .mat-column-apptTime,
.mat-mdc-table  .mat-column-apptStatus {
  max-width: 100px;
  width: 100px;
  padding-right: 1.25vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.mat-mdc-table .mat-column-note-body {
  max-width: 150px;
  width: 150px;
  padding-right: 1.25vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mat-mdc-table .mat-column-customField2 {
  max-width: 100px !important;
  width: 100px !important;
}

.mat-mdc-table .mat-column-customField2,
.mat-mdc-table .mat-column-note-body,
.mat-mdc-table .mat-column-location {
  max-width: 90px;
  width: 90px;
  padding-right: 1.25vw !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mat-mdc-table .mat-column-owner-fullName {
  max-width: 128px;
  width: 128px;
  padding-right: 1.25vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mat-mdc-table .mat-column-customField1 {
  max-width: 100px;
  width: 100px;
  padding-right: 1.25vw !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.mat-pseudo-checkbox {
  display: none !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
  background-color: rgba(0, 0, 0, .12) !important;
}

.mat-mdc-option:hover:not(.mat-option-disabled),
.mat-option.mat-option:focus:not(.mat-option-disabled) {
  background: #d3d3d3;
}

.cdk-overlay-container .mat-mdc-select-panel {
  border-radius: 0;
}

.mat-select-panel {
  min-width: 112px !important;
  max-width: 280px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 0;
  padding-bottom: 0;
  max-height: 256px;
  min-width: 100%;
  outline: 0;
}

.mat-step-header .mat-step-icon {
  width: 16px;
  height: 16px;
}

.mat-step-header .completed-step {
  border-radius: 50%;
  background-color: #09819c;
  width: 11px;
  height: 11px;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit,
.mat-step-header .mat-step-icon-state-number {
  background-color: transparent;
  border: 1px solid #748b99;
  color: #b6c3cc;
}

.mat-stepper-horizontal {
  background-color: transparent;
}

.mat-stepper-horizontal.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 60px;
  border-top-style: dashed;
  display: none;
}

.mat-stepper-horizontal.mat-stepper-label-position-bottom .mat-step-icon-state-number.mat-step-icon {
  background-color: transparent;
  border: 1px solid #748b99;
  color: #b6c3cc;
}

.mat-stepper-horizontal.mat-stepper-label-position-bottom
.mat-step-icon-state-number.mat-step-icon
.mat-step-icon-content
span.ng-star-inserted {
  display: none;
}

.mat-stepper-horizontal.mat-stepper-label-position-bottom .mat-horizontal-content-container {
  padding: 0;
}

.mat-stepper-horizontal.mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
  pointer-events: none !important;
  flex: auto;
  padding-top: 0;
  padding-bottom: 0;
}

.mat-stepper-horizontal.mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-label,
.mat-stepper-horizontal.mat-stepper-label-position-bottom
  .mat-horizontal-stepper-header.mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.54);
  padding: 11px 0 0 0;
  font-size: 12px;
  font-weight: 400;
}

.mat-stepper-horizontal.mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-label-top {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  font-weight: 400;
  padding: 0 0 11px 0;
}

.mat-stepper-horizontal.mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-optional {
  display: none;
}

.mat-stepper-horizontal.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before,
.mat-stepper-horizontal.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after {
  border-top-width: 1px;
  border-top-style: dashed;
  top: 36px;
  width: calc(50% - 8px);
}

.mat-stepper-horizontal.mat-stepper-label-position-bottom
  .mat-horizontal-stepper-header[completed="true"]:not(:first-child)::before,
.mat-stepper-horizontal.mat-stepper-label-position-bottom
  .mat-horizontal-stepper-header[completed="true"][half="true"]:not(:last-child)::after {
  border-top-width: 1px;
  border-top-style: solid;
  top: 36px;
  width: calc(50% - 8px);
  border-color: #09819c;
}

.mat-accordion .mat-expansion-panel {
  font-family: "Roboto", sans-serif !important;
  background-color: #ebeff2;
  color: #758a96;
  z-index: 1;
  transition: margin 225ms ease-in, box-shadow 280ms cubic-bezier(.4,0,.2,1);
}

.mat-accordion .mat-expansion-panel:last-of-type,
.mat-accordion .mat-expansion-panel:first-of-type {
  border-radius: 0;
  box-shadow: none;
}

.mat-accordion .mat-expansion-panel-header {
  font-family: "Roboto", sans-serif !important;
  padding: 10px 24px;
}

.mat-accordion .mat-expansion-panel-header-title {
  text-transform: uppercase;
  font-size: 24px;
  color: #677e8c;
}

.mat-mdc-checkbox-layout .mat-mdc-checkbox-inner-container .mat-mdc-checkbox-frame {
  border-width: 1px;
  background-color: white;
}

.mat-drawer-container {
  background-color: white;
}

.mat-mdc-slide-toggle .mat-slide-toggle-thumb-container,
.mat-slide-toggle .mat-slide-toggle-thumb-container:active {
  cursor: pointer;
}

.mat-mdc-tab-nav-bar,
.mat-mdc-tab-header {
  border-bottom-color: #0000004d;
}

.mat-mdc-tab-labels .mat-mdc-tab-label {
  min-width: auto;
  padding: 0;
  margin: 0 15px;
}

.mat-mdc-tab-label.mat-mdc-tab-label-active .mat-mdc-tab-label-content {
  font-weight: bold;
  color: #09819c;
}

.mat-mdc-tab-label-active {
  opacity: 1 !important;
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  height: 2px;
  background-color: #09819c;
}

.mat-mdc-tab-body-wrapper {
  padding: 30px;
}

.cdk-overlay-pane .mat-mdc-menu-panel {
  border-radius: 0;
}

.cdk-overlay-pane .mat-mdc-menu-content:not(:empty) {
  padding-top: 0;
  padding-bottom: 0;
}

.cdk-overlay-pane .mat-mdc-menu-item {
  line-height: 1em;
  height: 30px;
}

.cdk-overlay-pane .mat-mdc-menu-item:hover:not([disabled]),
.cdk-overlay-pane .mat-menu-item.cdk-program-focused:not([disabled]),
.cdk-overlay-pane .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.cdk-overlay-pane .mat-menu-item-highlighted:not([disabled]) {
  background-color: #09819c !important;
  color: #fff;
}

.cdk-overlay-backdrop {
  width: 100vw;
}

/* STOPS GOOGLE CHART TOOLTIP'S FLICKERING */
div.google-visualization-tooltip {
  pointer-events: none;
  z-index: 10;
}

.chart-tooltip-wrapper {
  box-sizing: content-box;
  background-color: white;
  text-align: left;
  padding: 20px 30px;
  width: 150px;
  box-shadow: 0 0 1px 0 rgb(204, 203, 203), 0 1px 0 0 rgba(0, 0, 0, 0.24), 0 3px 6px 0 rgba(0, 3, 0, 0.12);
}

.chart-tooltip-title {
  font-size: 22px;
  text-align: left;
  font-weight: 500;
}

.chart-tooltip-subtitle {
  font-size: 15px;
  display: flex;
  justify-content: space-between;
}

.chart-tooltip-value {
  text-align: right;
}

.colored-tooltip {
  background-color: white;
  padding: 5px 10px;
  white-space: normal;
  box-shadow:
    0 0 1px 0 rgb(204, 203, 203),
    0 1px 0 0 rgba(0, 0, 0, 0.24),
    0 3px 6px 0 rgba(0, 3, 0, 0.12);
}

.colored-tooltip-title {
  color: black;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 3px;
}

.colored-tooltip-category {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
}

.colored-tooltip-category-color {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  display: inline-block;
}

.colored-tooltip-category-label {
  font-size: 12px;
  font-weight: normal;
}

.tooltip-container {
  background-color: white;
  text-align: left;
  padding: 10px 20px;
  width: 150px;
  box-shadow: 0 0 1px 0 rgb(204, 203, 203), 0 1px 0 0 rgba(0, 0, 0, 0.24), 0 3px 6px 0 rgba(0, 3, 0, 0.12);
}

.tooltip-title {
  font-size: 20px;
  text-align: center;
}

.tooltip-subtitle {
  font-size: 16px;
  margin-bottom: -5px;
}

.que-time {
  color: #eab765;
}

.terminal-time {
  color: #ea9965;
}

.tooltip-subtitle-value {
  font-size: 19px;
  margin-bottom: 5px;
}

.tooltip-with-arrow {
  overflow: visible;
  width: 195px;
  text-align: center;
  word-wrap: break-word !important;
  word-break: unset !important;
}

app-dialog-component {
  position: relative;
}

.alert-message {
  margin: 4px 0;
  font-size: 0.7em;
  color: #d0021b;
  text-align: left;
  background-image: url(/assets/svgs/information-red.svg);
  background-repeat: no-repeat;
  background-position: 4px 2px;
  background-size: 16px 16px;
  padding-left: 28px;
}

.loader-container {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.spinner-overlay-parent {
  position: relative;
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1;
}

/* css to increase the current date dot size in graph*/
g text[text-anchor="middle"]:nth-child(3) {
  font-size: 16px;
}

.__no-data__ {
  display: none;
}

.mat-mdc-dialog-content {
  max-height: 80vh !important;
  overflow: inherit !important;
}

.mdc-line-ripple::before {
  border-bottom: 1px solid !important;
}

.mdc-line-ripple::after {
  border-bottom: 1px solid !important;
  border-bottom-color: #007acc !important;
}

.mat-mdc-option:focus.mdc-list-item, .mat-mdc-option.mat-mdc-option-active.mdc-list-item {
  background-color: var(--mat-option-focus-state-layer-color);
  outline: 0;
  background: rgba(0, 0, 0, .12) !important;
  color: #000000de !important;
}

.mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
   background-color: #007acc !important ;
}

.mdc-checkbox__mixedmark {
  color:white !important;
}

/* IE 10+ Hack */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html {
    overflow-y: auto;
  }
}

